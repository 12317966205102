import clone from 'lodash/clone';
import * as Yup from 'yup';
import { isPlateTest, isSeatsAmountTest } from './functions';

const phoneMatches = /^\(\d{2}\)?\s*\9?\d{4}\-?\d{4}$/;

export const validationFormLogin = Yup.object().shape({
  email: Yup.string().required('Campo obrigatório!').email('Digite um e-mail válido'),
  password: Yup.string()
    .required('Campo obrigatório!')
    .min(6, 'A senha precisa ter no mínimo 6 dígitos'),
});

export const validationFormForgotPassword = Yup.object().shape({
  email: Yup.string().email('Digite um e-mail válido').required('Campo obrigatório!'),
});

export const validationFormSignUp = Yup.object().shape({
  name: Yup.string()
    .required('Campo obrigatório!')
    .matches(/[^0-9\s]{2,}\s[^0-9\s]{2,}/, 'Informe nome e sobrenome.'),
  phone: Yup.string()
    .required('Campo obrigatório!')
    .min(14, 'Telefone inválido!')
    .max(15, 'Telefone inválido!')
    .matches(phoneMatches, 'Telefone inválido!'),
  email: Yup.string().required('Campo obrigatório!').email('Digite um e-mail válido'),
  password: Yup.string()
    .required('Campo obrigatório!')
    .min(6, 'A senha precisa ter no mínimo 6 dígitos')
    .matches(/[^a-zA-Z\s]+/, 'Necessário conter letras e números.')
    .matches(/[^0-9\s]+/, 'Necessário conter letras e números.'),
  confirmPassword: Yup.string()
    .required('Campo obrigatório!')
    .oneOf([Yup.ref('password'), ''], 'Confirmação de senha diferente da senha digitada.'),
});
export const validationForgotPassword = Yup.object().shape({
  password: Yup.string()
    .required('Campo obrigatório!')
    .min(6, 'Crie uma senha com no mínimo 6 caracteres utilizando letras e números.')
    .matches(/[^a-zA-Z\s]+/, 'Necessário conter letras e números.')
    .matches(/[^0-9\s]+/, 'Necessário conter letras e números.'),
  confirmPassword: Yup.string()
    .required('Campo obrigatório!')
    .oneOf([Yup.ref('password'), ''], 'Confirmação de senha diferente da senha digitada.'),
});
export const validationStepThree = Yup.object().shape({
  price: Yup.string()
    .required('Campo obrigatório!')
    .test('is-positive', 'Informe um valor diferente de 0.', value => value !== '0'),
});

export const validationFormCompanySignUp = Yup.object().shape({
  cnpj: Yup.string()
    .required('Campo obrigatório!')
    .min(11, 'CNPJ inválido!')
    .max(18, 'CNPJ inválido!')
    .matches(/^\d{2}\.\d{3}\.\d{3}\/\d{4}\-\d{2}$/, 'CNPJ inválido!'),
  companyName: Yup.string().required('Campo obrigatório!').min(3, 'Nome inválido!'),
  fantasyName: Yup.string().required('Campo obrigatório!').min(3, 'Nome inválido!'),
  cep: Yup.string().min(9, 'CEP Inválido').required('Campo obrigatório!'),
  uf: Yup.string().required('Campo obrigatório!').nullable(),
  city: Yup.string().required('Campo obrigatório!').nullable(),
  address: Yup.string().required('Campo obrigatório!'),
  number: Yup.string().required('Campo obrigatório!'),
  complement: Yup.string().min(3, 'Complemento inválido'),
  neighborhood: Yup.string().required('Campo obrigatório!'),
  contactName: Yup.string().required('Campo obrigatório!'),
  whatsapp: Yup.string()
    .when(['telephone'], {
      is: telephone => {
        return !telephone;
      },
      then: Yup.string().required('Preencha pelo menos um dos campos de contato.'),
    })
    .max(15, 'Número inválido')
    .matches(phoneMatches, 'Whatsapp inválido!'),
  telephone: Yup.string()
    .min(14, 'Telefone inválido!')
    .max(15, 'Telefone inválido!')
    .matches(phoneMatches, 'Telefone inválido!'),
  email: Yup.string().required('Campo obrigatório!').email('Digite um e-mail válido'),
  password: Yup.string()
    .required('Campo obrigatório!')
    .min(6, 'Crie uma senha com no mínimo 6 caracteres utilizando letras e números.')
    .matches(/[^a-zA-Z\s]+/, 'Necessário conter letras e números.')
    .matches(/[^0-9\s]+/, 'Necessário conter letras e números.'),
  confirmPassword: Yup.string()
    .required('Campo obrigatório!')
    .oneOf([Yup.ref('password'), ''], 'Confirmação de senha diferente da senha digitada.'),
});
const personalValidation = {
  contactName: Yup.string().required('Campo obrigatório!').min(3, 'Nome inválido!'),
  whatsapp: Yup.string()
    .when(['phone'], {
      is: phone => {
        return !phone;
      },
      then: Yup.string().required('Preencha pelo menos um dos campos de contato.'),
    })
    .max(15, 'Número inválido')
    .matches(phoneMatches, 'Whatsapp inválido!'),
  phone: Yup.string()
    .min(14, 'Telefone inválido!')
    .max(15, 'Telefone inválido!')
    .matches(phoneMatches, 'Telefone inválido!'),
  secondPhone: Yup.string()
    .min(14, 'Telefone inválido!')
    .max(15, 'Telefone inválido!')
    .matches(phoneMatches, 'Telefone inválido!'),
  thirdPhone: Yup.string()
    .min(14, 'Telefone inválido!')
    .max(15, 'Telefone inválido!')
    .matches(phoneMatches, 'Telefone inválido!'),
  email: Yup.string().required('Campo obrigatório!').email('Digite um e-mail válido'),
  secondEmail: Yup.string().email('Digite um e-mail válido'),
  thirdEmail: Yup.string().email('Digite um e-mail válido'),
};
export const fullProfileValidation = Yup.object().shape({
  displayName: Yup.string().required('Campo obrigatório!').min(3, 'Nome inválido!'),
  cep: Yup.string().min(9, 'CEP Inválido').required('Campo obrigatório!'),
  state: Yup.string().required('Campo obrigatório!'),
  city: Yup.string().required('Campo obrigatório!'),
  address: Yup.string().required('Campo obrigatório!'),
  num: Yup.string().required('Campo obrigatório!'),
  complement: Yup.string().min(3, 'Complemento inválido'),
  neighborhood: Yup.string().required('Campo obrigatório!'),
  ...personalValidation,
});

export const changePasswordValidation = Yup.object().shape({
  password: Yup.string()
    .required('Campo obrigatório!')
    .min(6, 'A senha precisa ter no mínimo 6 dígitos')
    .matches(/[^a-zA-Z\s]+/, 'Necessário conter letras e números.')
    .matches(/[^0-9\s]+/, 'Necessário conter letras e números.'),
  confirmPassword: Yup.string()
    .required('Campo obrigatório!')
    .oneOf([Yup.ref('password'), ''], 'Confirmação de senha diferente da senha digitada.'),
});

export const profileValidation = Yup.object().shape({
  ...personalValidation,
});

export function validarCNPJ(cnpj: string) {
  if (cnpj !== undefined && cnpj !== '') {
    cnpj = cnpj.replace(/[^\d]+/g, '');

    if (cnpj === '') {
      return false;
    }

    if (cnpj.length !== 14) {
      return false;
    }

    if (
      cnpj === '00000000000000' ||
      cnpj === '11111111111111' ||
      cnpj === '22222222222222' ||
      cnpj === '33333333333333' ||
      cnpj === '44444444444444' ||
      cnpj === '55555555555555' ||
      cnpj === '66666666666666' ||
      cnpj === '77777777777777' ||
      cnpj === '88888888888888' ||
      cnpj === '99999999999999'
    ) {
      return false;
    }

    let tamanho = cnpj.length - 2;
    let numeros: any = cnpj.substring(0, tamanho);
    const digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) {
        pos = 9;
      }
    }
    let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado.toString() !== digitos.charAt(0)) {
      return false;
    }

    tamanho = tamanho + 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) {
        pos = 9;
      }
    }
    resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado.toString() !== digitos.charAt(1)) {
      return false;
    }

    return true;
  }
  return;
}
export const validationStep1Plate = Yup.object().shape({
  plate: Yup.string().min(7, 'Digite 7 caracteres no campo.').required('Campo obrigatório!'),
});

const vehicleValidation = {
  plate: Yup.string().test(
    'valid-plate',
    'Informe uma placa valida.',
    value => !value || isPlateTest(value),
  ),

  brand: Yup.string().required('Campo obrigatório!'),
  model: Yup.string().required('Campo obrigatório!'),

  fabricationYear: Yup.string().required('Campo obrigatório!'),
  modelYear: Yup.string().required('Campo obrigatório!'),

  fuelType: Yup.string().required('Campo obrigatório!'),
  color: Yup.string().required('Campo obrigatório!'),

  city: Yup.string().required('Campo obrigatório!'),
  state: Yup.string().required('Campo obrigatório!'),
};

export const validateCarFields = Yup.object().shape({
  ...vehicleValidation,
  transmission: Yup.string().required('Campo obrigatório!'),
  engine: Yup.string().required('Campo obrigatório!'),
  ports: Yup.string().required('Campo obrigatório!'),
});

export const validateMotorcycleFields = Yup.object().shape({
  ...vehicleValidation,
  bodyStyle: Yup.string().required('Campo obrigatório!'),
  matchType: Yup.string().required('Campo obrigatório!'),
  motor: Yup.string().required('Campo obrigatório!'),
});

export const validateTruckFields = Yup.object().shape({
  ...vehicleValidation,
  axles: Yup.string().required('Campo obrigatório!'),
  transmission: Yup.string().required('Campo obrigatório!'),
  cargoVehicleType: Yup.string().required('Campo obrigatório!'),
});

export const validateNauticalFields = () => {
  const { plate, ...vehicleValidationClone } = clone(vehicleValidation);

  return Yup.object().shape({
    ...vehicleValidationClone,
    bodyStyle: Yup.string().required('Campo obrigatório!'),
  });
};

export const validateAgraleFields = () => {
  const { plate, ...vehicleValidationClone } = clone(vehicleValidation);

  return Yup.object().shape({
    ...vehicleValidationClone,
    bodyStyle: Yup.string().required('Campo obrigatório!'),
    transmission: Yup.string().required('Campo obrigatório!'),
  });
};

export const validateBusFields = Yup.object().shape({
  ...vehicleValidation,
  bodyStyle: Yup.string().required('Campo obrigatório!'),
  transmission: Yup.string().required('Campo obrigatório!'),
  ports: Yup.string().required('Campo obrigatório!'),
  seatsAmount: Yup.string()
    .required('Campo obrigatório!')
    .test('is-seats-amount', 'Este valor não pode ser menor que 15.', value =>
      isSeatsAmountTest(parseInt(value, 10)),
    ),
  busType: Yup.string().required('Campo obrigatório!'),
});

export const validationPartsFields = Yup.object().shape({
  name: Yup.string().required('Campo obrigatório!'),
  description: Yup.string().required('Campo obrigatório!'),
  modelType: Yup.string().required('Campo obrigatório!'),
  partType: Yup.string().required('Campo obrigatório!'),
  limitCount: Yup.string().required('Campo obrigatório!'),
  warranty: Yup.string().required('Campo obrigatório!'),
  price: Yup.string().test(
    'is-positive',
    'Informe um valor diferente de 0.',
    value => value !== '0',
  ),
  models: Yup.array().required('Campo obrigatório!').nullable(),
  partCategorie: Yup.string().required('Campo obrigatório'),
});

export const validationSendProposal = Yup.object().shape({
  name: Yup.string()
    .required('Campo obrigatório!')
    .matches(/[^0-9\s]{2,}\s[^0-9\s]{2,}/, 'Informe nome e sobrenome.'),
  email: Yup.string().required('Campo obrigatório!').email('Digite um e-mail válido'),
  phone: Yup.string()
    .required('Campo obrigatório!')
    .min(14, 'Telefone inválido!')
    .max(15, 'Telefone inválido!')
    .matches(phoneMatches, 'Telefone inválido!'),
  proposal: Yup.string().required('Campo obrigatório!'),
});
